
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import OrderServiceApi from '@/api/http/OrderServiceApi';
import { Tip } from '@/interfaces/models/Tip';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import moment from 'moment';

const app = namespace('app');
const api = new OrderServiceApi();

interface Selection {
  from?: string;
  to?: string;
  venue?: string;
}

@Component({
  components: { VRangePicker },
})
export default class Start extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];

  public venue: string = '';
  public range = {
    from: '',
    to: '',
  };

  get canFetch(): boolean {
    return !!this.selection.venue && !!this.selection.from && !!this.selection.to;
  }

  public tipsData: Tip | null = null;

  get selection() {
    const selection: Selection = {};
    if (this.venue) {
      selection.venue = this.venue;
    }
    if (this.range && this.range.from && this.range.to) {
      selection.from = this.range.from;
      selection.to = this.range.to;
    }

    return selection;
  }

  public async exportCsv() {
    if (this.selection.venue && this.selection.from && this.selection.to) {
      this.$startLoading('csv');
      api
        .exportTipsCsv(this.selection.venue, this.selection.from, this.selection.to)
        .then((res) => {
          // @ts-ignore
          if (res.data === 'OK') {
            this.notifySuccess('notification.successfulDownload');
          }
        })
        .finally(() => {
          this.$stopLoading('promo.summaryCsv');
        });
      this.$stopLoading('csv');
    }
  }

  @Watch('selection')
  public async onSelectionChange() {
    if (this.canFetch) {
      const { data } = await api.exportTips(
        this.selection.venue as string,
        this.selection.from as string,
        this.selection.to as string,
      );
      this.tipsData = data;
    }
  }
}
